<template>
<div
  class="row mt-4"
  v-if="studioDescriptionExists(studio.about_text, studio.about_text_new)"
>
  <div class="col">
    <div class="d-flex justify-content-between align-items-center pb-1 mb-3">
      <h4 class="m-0">{{ $t('STUDIO.SECTIONS.WELCOME') }}</h4>
      <StudioSocial
        v-if="!studioDescriptionExists(studio.about_studio, studio.about_studio_new)"
        :studio="studio"
      />
    </div>
    <HMCustomContent
      :html="studio.about_text"
      :json="studio.about_text_new"
    />
    <!-- <div class="text-end">
      <span
        class="text-decoration-underline cursor-pointer c-charcoal"
        @click="showMore = !showMore"
      >
        {{ showMore ? $t('STUDIO.LABELS.READ_LESS') : $t('STUDIO.LABELS.READ_MORE') }}
      </span>
    </div> -->
  </div>
</div>
</template>

<script lang="ts">
import StudioTemplateMixin from '@/mixins/StudioTemplateMixin'

export default {
  name: 'InstructorWelcome',
  mixins: [StudioTemplateMixin],
  data() {
    return {
      showMore: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.clean-content-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
