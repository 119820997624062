<template>
  <div v-if="meetings.length > 0" class="mt-4">
    <div class="row">
      <div class="col">
        <hr class="mb-4">
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-between align-items-center">
        <h4>{{ $t('STUDIO.TITLE.SESSIONS') }}</h4>
        <div class="d-flex gap-3">
          <div
            class="meetings-prev navigation-btn"
            v-show="swiperActiveIndex > 0"
            @click="$refs.swiper.swiper.slidePrev()"
          >
            <i class="far fa-chevron-left"></i>
          </div>
          <div
            class="meetings-next navigation-btn"
            :class="{
              'hidden': swiperActiveIndex >= meetings.length - 1,
            }"
            @click="$refs.swiper.swiper.slideNext()"
          >
            <i class="far fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <swiper-container
          ref="swiper"
          slides-per-view="auto"
          space-between="32"
          @swiperslidechange="setSwiper"
        >
          <swiper-slide
            class="slide"
            v-for="(product, $index) in meetings"
            :key="$index"
          >
            <NuxtLink
              class="undecorated-router-link slide"
              :to="{
                name: 'studio-book-meeting',
                params: { meeting_type_slug: product.slug },
              }"
            >
              <StudioProductCard :product="product" size="sm" />
            </NuxtLink>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStudioStore } from '~/stores'
import { useI18n } from 'vue-i18n'

const { currentStudio: studio } = storeToRefs(useStudioStore())
const swiper = ref<SwiperType | null>(null)
const { t } = useI18n()
const swiperActiveIndex = ref(0)

const setSwiper = (swiperControl: SwiperType) => {
  swiperActiveIndex.value = swiper.value?.swiper.activeIndex;
}

const meetings = computed(() => {
  return studio.value?.meetings.map(meeting => ({
    ...meeting,
    product_name: meeting.name,
    is_session: true,
    pricing_type: meeting.require_payment && meeting.price > 0 ? 'fixed' : 'free',
    cover_file: meeting.cover_photo,
    tags: [{ name: t('STUDIO.LABELS.SESSION') }],
  }))
})
</script>

<style scoped lang="scss">
@import '@/styles/components-variables.scss';

.navigation-btn {
  color: $charcoal;
  cursor: pointer;
  padding: 0 0.5rem;
}

.slide {
  max-width: 245px;
  padding-top: 16px;
}
</style>
