<template>
  <div class="creator-home-template">
    <CreatorTeacherCard />
    <CreatorAbout />
    <CreatorStudioLinks />
    <CreatorCustomLinks />
    <hr class="mt-4 mb-0">
    <CreatorWelcome />
    <CreatorProductCard :product="product"/>
    <CreatorShop />
    <CreatorMeetings />
  </div>
</template>

<script>
import StudioTemplateMixin from '@/mixins/StudioTemplateMixin';
import CreatorTeacherCard from '@/components/templates-common/CreatorTeacherCard.vue';
import CreatorAbout from '@/components/templates-common/CreatorAbout.vue';
import CreatorWelcome from '@/components/templates-common/CreatorWelcome.vue';
import CreatorProductCard from '@/components/templates-common/CreatorProductCard.vue';
import CreatorCustomLinks from '@/components/templates-common/CreatorCustomLinks.vue';
import CreatorShop from '@/components/templates-common/CreatorShop.vue';
import CreatorStudioLinks from '@/components/templates-common/CreatorStudioLinks.vue';
import CreatorMeetings from '@/components/templates-common/CreatorMeetings.vue';

export default {
  name: 'StudioHomeTemplateCreator1',
  mixins: [StudioTemplateMixin],
  components: {
    CreatorTeacherCard,
    CreatorAbout,
    CreatorWelcome,
    CreatorProductCard,
    CreatorCustomLinks,
    CreatorShop,
    CreatorStudioLinks,
    CreatorMeetings,
  },
  computed: {
    product() {
      return this.studio.featured_product
    },
  },
  methods: {},
  mounted() {
    if (this.studio.studio_embed1) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(this.studio.studio_embed1);
      this.$refs['studio-embed1'].append(scriptEl);
    }
    if (this.studio.studio_embed2) {
      const scriptEl = document
        .createRange()
        .createContextualFragment(this.studio.studio_embed2);
      this.$refs['studio-embed2'].append(scriptEl);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.creator-home-template {
  max-width: 632px;
  margin-bottom: 100px;
}
</style>
