<template>
  <div class="studio-template"
    :class="{ 'studio-page': !hideTopBar }">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <StudentNavigation v-if="showStudentNavigation"
            class="mt-4">
          </StudentNavigation>
        </div>
      </div>
    </div>
    <StudioTopBar 
      v-if="!hideTopBar"
      :hideTopBarNavigation="selectedTemplate.config.hideTopBarNavigation"
    ></StudioTopBar>
    <div class="studio-home"
      v-if="showRouterView"
      :class="`${selectedTemplate.config.studioBackgroundColorClass} ${selectedTemplate.config.studioTopPadding ? 'pad' : ''}`">
      <component v-if="studioStore.currentStudio"
        class="container"
        :is="selectedTemplate.component"></component>
      <AddToHomeScreenBanner class="d-md-none add-to-home-screen-banner" />
    </div>
    <ExpiredStudio v-if="!showRouterView" />

    <div class="container text-center studio-instagram"
      v-if="studio">
      <link :href="`https://fonts.googleapis.com/css2?family=${studio.default_font}:wght@400;500;700&display=swap`"
        rel="stylesheet" />
      <div class="mt-5"></div>
      <InstagramPhotosSlider
        v-if="!selectedTemplate.config.hideInstagramSlider"
        class="mb-5"
        :studioId="studio.id"
        :key="studio.id" 
      /> 
    </div>
    <client-only>
      <HMCookieNotice class="cookie-notice"
        :class="{ 'cookie-notice--nav': !hideMobileNav }"
        v-if="!isPWA && !hideCookieNotice" />
    </client-only>
    <StudioMobileNavigation class="studio-mobile-navigation"
      v-if="!hideMobileNav" />
    <ViewAsTeacherFooter class="view-as-teacher-footer" />
    <StudioFooter
      v-if="!selectedTemplate.config.hideStudioFooter"
      :forceHideStudioContactInformation="selectedTemplate.config.hideStudioContactInformation"
    />
  </div>
</template>

<script setup lang="ts">
import { useStudioStore } from '@/stores';

definePageMeta({
  name: 'studio-home-page',
  layout: 'clean',
  isStudio: true,
});

const studioStore = useStudioStore();
const selectedTemplate = studioStore.selectedTemplate;
</script>

<script lang="ts">
import { mapState } from 'pinia';

import AddToHomeScreenBanner from '@/components/pwa/AddToHomeScreenBanner.vue';
import StudioHomeTemplateClassic from '@/components/studio-templates/StudioHomeTemplateClassic.vue';
import StudioHomeTemplateModern from '@/components/studio-templates/StudioHomeTemplateModern.vue';
import StudioHomeTemplateCards from '@/components/studio-templates/StudioHomeTemplateCards.vue';
import StudioHomeTemplateBrandColor from '@/components/studio-templates/StudioHomeTemplateBrandColor.vue';
import StudioHomeTemplateProductBanner from '@/components/studio-templates/StudioHomeTemplateProductBanner.vue';
import StudioHomeTemplateCreator1 from '@/components/studio-templates/StudioHomeTemplateCreator1.vue';
import StudioHomeTemplateCreator2 from '@/components/studio-templates/StudioHomeTemplateCreator2.vue';

export default {
  name: 'StudioHome',
  components: {
    AddToHomeScreenBanner,
    StudioHomeTemplateClassic,
    StudioHomeTemplateModern,
    StudioHomeTemplateCards,
    StudioHomeTemplateBrandColor,
    StudioHomeTemplateProductBanner,
    StudioHomeTemplateCreator1,
    StudioHomeTemplateCreator2,
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
      eventStartingSoon: 'eventStartingSoon',
      selectedTemplate: 'selectedTemplate',
    }),
    ...mapState(useGlobalsStore, {
      showLoginModal: 'showLoginModal',
      isPWA: 'isPWA',
      showUserbackInStudio: 'showUserbackInStudio',
    }),
    showRouterView() {
      if (!this.studio || this.$route.meta?.ignoreStudioExpiration) {
        return true;
      }
      return !this.studio.is_expired;
    },
    hideMobileNav() {
      if (typeof this.selectedTemplate?.config?.hideMobileNav === 'boolean') {
        return this.selectedTemplate.config.hideMobileNav;
      }
      return this.$route.meta.hideMobileNav;
    },
    hideCookieNotice() {
      if (typeof this.selectedTemplate?.config?.hideCookieNotice === 'boolean') {
        return this.selectedTemplate.config.hideCookieNotice;
      }
      return this.$route.meta?.hideCookieNotice;
    },
    hideTopBar() {
      if (typeof this.selectedTemplate?.config?.hideTopBar === 'boolean') {
        return this.selectedTemplate.config.hideTopBar;
      }
      return this.$route.meta?.hideTopBar;
    },
    showStudentNavigation() {
      if (typeof this.selectedTemplate?.config?.showStudentNavigation === 'boolean') {
        return this.selectedTemplate.config.showStudentNavigation;
      }
      return this.$route.meta?.showStudentNavigation;
    },
  }

};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.studio-home {
  &.pad {
    @include media-breakpoint-up(sm) {
      padding-top: $shop-topbar-height;
    }
  }
}

.add-to-home-screen-banner {
  bottom: 70px;
}

.studio-mobile-navigation {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.studio-template .cookie-notice--nav {
  @media screen and (max-width: 768px) {
    bottom: 70px;
  }
}

.view-as-teacher-footer {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

</style>
