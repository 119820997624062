<template>
  <div class="clean-teacher-card p-4 p-md-5 mt-5 mt-md-0">
    <div class="me-md-4">
      <img :src="studio?.profile_photo?.thumbnail" class="clean-teacher-card-image">
    </div>
    <div class="flex-1 mt-3 mt-md-0">
      <h1 class="m-0">
        {{ studio.studio_name }}
      </h1>
      <span class="h4 d-flex flex-row align-items-center gap-2 cursor-pointer" v-if="studio.tip_jar_enabled" @click="openTipModal">
        <HMCustomIcon class="bg-dark" icon="hearts" />
        {{ $t('STUDIO.BUTTON.LEAVE_A_TIP') }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStudioStore } from '~/stores'
import TipJarModal from '@/components/settings/TipJarModal';

const { $modal } = useNuxtApp()

const {
  currentStudio: studio,
  currency
} = storeToRefs(useStudioStore())

const openTipModal = () => {
  $modal.show(TipJarModal, {
    studioName: studio.value.studio_name,
    currency: currency.value,
  });
}
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.clean-teacher-card {
  border: 1px solid $medium-gray;
  border-radius: $border-radius * 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.clean-teacher-card-image {
  width: 134px;
  height: 134px;
  object-fit: cover;
  border-radius: 50%;
}
</style>
