<template>
  <div class="d-flex flex-row gap-3" v-if="studio.has_events || studio.tip_jar_enabled">
    <span
      class="custom-link mt-4 p-3 cursor-pointer d-flex flex-row align-items-center gap-2 w-100"
      v-if="studio.tip_jar_enabled"
      @click="openTipModal"
    >
      <HMCustomIcon class="bg-dark" icon="hearts" />
      {{ $t('STUDIO.BUTTON.LEAVE_A_TIP') }}
    </span>
    <NuxtLink
      class="custom-link mt-4 p-3 cursor-pointer d-flex flex-row align-items-center gap-2 w-100"
      :to="{ name: 'studio-calendar' }"
      v-if="studio.has_events"
    >
      <i class="far fa-calendar"></i>
      {{ $t('STUDIO.LINKS.VIEW_CALENDAR') }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { useStudioStore } from '~/stores'
import TipJarModal from '@/components/settings/TipJarModal';

const { $modal } = useNuxtApp()

const {
  currentStudio: studio,
  currency
} = storeToRefs(useStudioStore())

const openTipModal = () => {
  $modal.show(TipJarModal, {
    studioName: studio.value.studio_name,
    currency: currency.value,
  });
}
</script>

<style scoped lang="scss">
@import '@/styles/components-variables.scss';

.custom-link {
  border: 1px solid $medium-gray;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  transition: all 0.3s ease-in-out;
  color: $dark;

  &:hover {
    box-shadow: 0 12px 12px -6px $dark-gray;
    transform: translateY(-16px);
  }
}
</style>