<template>
  <div v-if="customLinks.length > 0">
    <a
      v-for="link in customLinks"
      :href="link.link"
      class="custom-link mt-4 p-3 undecorated-router-link"
      target="_blank"
    >
      <HMCustomIcon class="bg-dark" icon="arrow-up-right" />
      {{ link.label }}
    </a>
  </div>
</template>

<script setup lang="ts">
import { useStudioStore } from '~/stores'

const { currentStudio: studio } = storeToRefs(useStudioStore())

const customLinks = computed(() => {
  return [
    {
      label: studio.value.custom_nav_label_1,
      link: studio.value.custom_nav_link_1,
    },
    {
      label: studio.value.custom_nav_label_2,
      link: studio.value.custom_nav_link_2,
    },
    {
      label: studio.value.custom_nav_label_3,
      link: studio.value.custom_nav_link_3,
    },
  ].filter(link => link.label && link.link) as { label: string, link: string }[]
})
</script>

<style scoped lang="scss">
@import '@/styles/components-variables.scss';

.custom-link {
  border: 1px solid $medium-gray;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  transition: all 0.3s ease-in-out;
  color: $dark;
  gap: 0.5rem;

  &:hover {
    box-shadow: 0 12px 12px -6px $dark-gray;
    transform: translateY(-16px);
  }
}
</style>
