<template>
  <div v-if="products.length > 0" class="mt-4">
    <div class="row">
      <div class="col">
        <hr class="mb-4">
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-between align-items-center">
        <h4>{{ $t('STUDIO.SECTIONS.SHOP') }}</h4>
        <div class="d-flex gap-3">
          <div
            class="navigation-btn shop-prev"
            @click="swiper?.swiper.slidePrev()"
            v-show="swiperActiveIndex > 0"
          >
            <i class="far fa-chevron-left"></i>
          </div>
          <div
            class="navigation-btn shop-next"
            :class="{
              'hidden': swiperActiveIndex >= products.length - 1,
            }"
            @click="swiper?.swiper.slideNext()"
          >
            <i class="far fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <swiper-container
          ref="swiper"
          slides-per-view="auto"
          space-between="32"
          @swiperslidechange="setSwiper"
        >
          <swiper-slide
            class="slide"
            v-for="(product, $index) in products"
            :key="$index"
          >
            <NuxtLink
              class="undecorated-router-link slide"
              :to="{
                name: 'studio-product',
                params: { product_id: product.id },
              }"
            >
              <StudioProductCard :product="product" size="sm" />
            </NuxtLink>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStudioStore } from '~/stores'

const swiperActiveIndex = ref(0)


const { currentStudio: studio } = storeToRefs(useStudioStore())
const swiper = ref<SwiperType | null>(null)


const products = computed(() => {
  return studio.value?.products;
})

const setSwiper = (swiperControl: SwiperType) => {
  swiperActiveIndex.value = swiper.value?.swiper.activeIndex;
}
</script>

<style scoped lang="scss">
@import '@/styles/components-variables.scss';

.navigation-btn {
  color: $charcoal;
  cursor: pointer;
  padding: 0 0.5rem;
}

.slide {
  max-width: 245px;
  padding-top: 16px;
}
</style>
